<!-- 隔离居民列表 （隔离管理-已入住人员列表） By阿旭 -->
<template>
  <div class="main">
    <template v-if="!isShowDetail">
      <div class="condition-filtrate-box">
        <div class="left-item">
          <div class="row-item">
            <el-input class="select-width" size="medium" v-model="queryForm.searchValue" placeholder="居民姓名/手机号" />
            <el-input class="select-width" size="medium" v-model="queryForm.roomNo" placeholder="房间号" />
            <el-input class="select-width id-code-width" size="medium" v-model="queryForm.cardNo" placeholder="证件号" />

            <el-select class="select-width" size="medium" v-model="queryForm.country" filterable placeholder="国籍">
              <el-option v-for="(item, v) in nationalitySelect" :key="v" :label="item.label" :value="item.label" />
            </el-select>
            <el-select class="select-width" size="medium" v-model="queryForm.crowdTypeId" filterable placeholder="选择人群分类">
              <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                :value="item.id"></el-option>
            </el-select>
          </div>

          <div class="row-item">
            <el-select class="select-width" size="medium" v-model="queryForm.status" placeholder="状态">
              <el-option v-for="(item, v) in checkstatusselect" :key="v" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-select v-btn-permission="'008001002001'" class="select-width" size="medium"
              v-model="queryForm.isolationPointId" placeholder="隔离点">
              <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
            </el-select>

            <el-date-picker unlink-panels v-model="timetime" size="medium" type="daterange" value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
          </div>
        </div>

        <div class="right-item" style="position: absolute; right: 0; bottom: 16px;">
          <el-button type="primary" size="small" @click="queryDataInfo('btn')">查询</el-button>
          <el-button type="primary" size="small" @click="cz()">重置</el-button>
          <el-button type="primary" size="small" @click="exportListData" v-loading.fullscreen.lock="fullscreenLoading">
            导出</el-button>
          <el-button type="primary" size="small" @click="isfbtc = true">导出分表</el-button>
<!--          <el-button type="primary" size="small" @click="newadd()" v-loading.fullscreen.lock="fullscreenLoading">导出总汇-->
<!--          </el-button>-->
        </div>
      </div>

      <TableModel :is-show-select="true" :is-show-tabs="false" :loading="loading" :is-fixed="true"
        :table-data="tableData" table-size="medium">
        <el-table-column label="操作" width="170">
          
          <template slot-scope="{ row }">

            <span v-if="queryForm.status==''||queryForm.status=='1'">
              <el-link v-if="row.applyRelieveStatus == 2||keydata=='0'" v-btn-permission="'008001002002'" @click="arrange(row)" type="primary" slot="reference">解除隔离</el-link>
            <el-link type="primary" @click="prepareRelieve(row)" v-btn-permission="'008001002003'" v-if="row.applyRelieveStatus == 1||row.applyRelieveStatus == 0||row.applyRelieveStatus == 3">预解除隔离
            </el-link>
            </span>

            <span v-if="queryForm.status=='0'">
              <el-link type="primary" @click="prepareRelieve(row)" v-btn-permission="'008001002003'" v-if="row.applyRelieveStatus == 1||row.applyRelieveStatus == 0||row.applyRelieveStatus == 3">预解除隔离
            </el-link>
            </span>

            <el-link type="primary" @click="delItemData(row)" v-btn-permission="'008001002005'">删除</el-link>
            <el-link type="primary" @click="dictionaryFun(row)" v-btn-permission="'008099'">查看更多</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="居民信息" width="80" />
        <el-table-column prop="cardType" label="证件类型" width="96">
          <template slot-scope="scope">
            <div style="white-space: nowrap" v-if="scope.row.cardType == 1">居民身份证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 2">护照</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 3">港澳通行证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 4">台胞证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 5">其他</div>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="证件号" width="170" />
        <el-table-column prop="roomNo" label="房间号" width="70" />
        <el-table-column prop="isolationPointName" label="隔离点" />
        <el-table-column prop="crowdTypeName" label="人群分类" />
        <el-table-column prop="regTime" label="登记时间" />
        <el-table-column prop="startTime" label="开始隔离时间" />
        <el-table-column prop="endTime" label="预解除隔离时间" />
        <el-table-column prop="transferTime" label="解除隔离时间">
          <template slot-scope="{ row }">
            {{ row.transferTime ? row.transferTime : "-" }}
          </template>
        </el-table-column>

        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="{ row }">
            <el-tag size="small" type="warning" v-if="row.status == 1">隔离中</el-tag>
            <el-tag size="small" type="success" v-if="row.status == 0">已解除</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="transferType" label="转出类型">
          <template slot-scope="scope">
            <div style="white-space: nowrap" v-if="scope.row.transferType == 1">解除隔离</div>
            <div style="white-space: nowrap" v-else-if="scope.row.transferType == 2">送医治疗</div>
            <div style="white-space: nowrap" v-else-if="scope.row.transferType == 3">送往隔离点</div>
            <div style="white-space: nowrap" v-else-if="scope.row.transferType == 4">居家隔离</div>
            <div style="white-space: nowrap" v-else-if="scope.row.transferType == 5">居家监测</div>
            <div style="white-space: nowrap" v-else-if="scope.row.transferType == 9">其他</div>
            <div style="white-space: nowrap" v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="transferDestination" label="转出目的地">
          <template slot-scope="{ row }">
            {{ row.transferDestination ? row.transferDestination : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注说明">
          <template slot-scope="{ row }">
            {{ row.remark ? row.remark : "-" }}
          </template>
        </el-table-column>
      </TableModel>

      <div class="content_page">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
          :total="total" :page-sizes="[10, 20, 30, 50, 100]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </div>
    </template>

    <!-- 导出分表 -->
    <el-dialog v-if="isfbtc" title="导出分表" :visible.sync="isfbtc" width="20%" :before-close="closeConfirmoperation">
      <el-select style="margin-right:10px;" v-model="crowdTypeId" filterable placeholder="选择人群分类">
        <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName" :value="item.id" @click.native="handelCrowdType(item)">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeConfirmoperation">取 消</el-button>
        <el-button type="primary" @click="exportRegLiveCrowdType" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>

    <!-- 解除隔离 弹窗 -->
    <RelieveQuarantine ref="RelieveQuarantineRef" input-type="待办任务" />

    <!--预解除隔离 弹窗-->
    <PrepareRelieve ref="PrepareRelieveRef" />

    <!--删除弹窗-->
    <DelResidentDialog ref="DelResidentDialogRef" dialog-title="删除信息" />

  </div>
</template>

<script>
import {
  residentlistt,
  exportTotal,
  exportRegLiveCrowdTypeApi,
  exportRegLiveListApi
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import {configKey} from "../../api/SystemManagement/isolationpointmanagement";
import { getAllRuleCrowdList } from "@/api/RuleManagement/crowdAnalysis";
import residentInformation from "./residentInformation";
import DelResidentDialog from './modules/DelResidentDialog';
import RelieveQuarantine from '../Taskmanagement/modules/RelieveQuarantine';
import PrepareRelieve from '../Taskmanagement/modules/PrepareRelieve';
import TableModel from '../../components/TableModel';
import constants from '../../common/constants';
import { mapState } from 'vuex';
import { messageBox, importFile } from '../../utils/utils';
import {
  downloadRelieveSignBook,
  relieveIsolate,
  sendRelieveSignBook
} from '@/api/QuarantineRegistration/quarantineRegistrationpop';
import moment from "moment/moment";
export default {
  components: {
    residentInformation,
    DelResidentDialog,
    TableModel,
    RelieveQuarantine,
    PrepareRelieve
  },
  prpos: ["isdisabled"],
  data() {
    return {
      keydata:'',  //判断是否为 需要申请
      loading: false,
      isjcgl: false,
      isjjjc: false,
      isjjgl: false,
      ruleForm: {
        applyRelieveTime: "",
        transferType: '',
        transferDestination: '',
        remark: ""
      },
      fullscreenLoading: false,
      queryForm: {
        searchValue: "",
        roomNo: "",
        cardNo: "",
        country: "",
        crowdTypeId: "",
        status: "1",
        isolationPointId: "",
      }, //查询表单
      typeCertificate: constants.TYPE_CERTIFICATE, //身份证类型
      checkstatusselect: constants.CHECK_STATUS_SELECT, //核查状态
      typepopSelect:[],// 人群分类（所有）
      tableData: [], //表单数据
      isShowDetail: false,
      isfbtc: false,
      timetime: [],
      regId: "",
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      crowdType: "", //导出分表-人群分类编码
      crowdTypeId: "", //导出分表-人群分类Id
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      xqform: {},
      rules: {
        applyRelieveTime: [{ required: true, message: "请选择解除隔离时间", trigger: "blur" }],
        transferType: [{ required: true, message: "请选择转出类型", trigger: "blur" }],
        transferDestination: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }]
      },
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,  // 隔离点列表数据
      nationalitySelect: state => state.user.nationalitySelect,  // 国籍
    }),
  },

  created() {
    this.getListInfo();
    this.isSystemCs();
    // 获取人群分类(所有)
    this.getAllRuleCrowdList();
  },
  watch: {
    isShowDetail(newVal) {
      if (!newVal) {
        this.getListInfo();
      }
    }
  },
  methods: {
     // 获取是否为 申请预解除隔离
     async isSystemCs(){
      let configkey = 'hotel_relieve_apply';
      let result = await configKey(configkey);
      if (result.data.code ==200){
        this.keydata=result.data.data
      }
    },
    // 人群分类(所有)
    async getAllRuleCrowdList() {
      const { data } = await getAllRuleCrowdList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    // 安排隔离
    arrange(row) {
      this.$refs.RelieveQuarantineRef.show(row);
    },

    // 预解除隔离
    prepareRelieve(row) {
      this.$refs.PrepareRelieveRef.show(row)
    },

    // 关闭导出弹窗
    closeConfirmoperation() {
      this.isfbtc = false;
    },

    // 删除信息
    delItemData(row) {
      this.$refs.DelResidentDialogRef.show(row.id);
    },

    // 查看更多
    dictionaryFun(row) {
      console.log(row,'666666666');
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row)
      })
    },

    cz() {
      this.timetime = [];
      this.queryForm = {
        searchValue: "",
        roomNo: "",
        cardNo: "",
        country: "",
        crowdTypeId: "",
        status: "1" //默认状态为隔离中的人员（展示）
      };
      this.getListInfo(); //查询列表
    },

    //导出分表-选择人群分类
    handelCrowdType(item) {
      this.crowdType = item.code;
      this.crowdTypeName = item.ruleName;
    },

    // 导出分表-人群分类
    async exportRegLiveCrowdType() {
      try {
          let params = this.setParamsInfo();
          params.crowdTypeId = this.crowdTypeId;
          params.crowdType = this.crowdType;

          if (!params.crowdTypeId) {
            this.$message.warning("请选择人群分类");
            return false;
          }

          if(params.status != "1"){
            //如状态选择的全部或已解除时，时间范围必须选择，并且最多3个月的数据。如未选择提示“请选择开始时间和结束时间，最多只能导出3个月数据”
            if (!params.startTimeMin) {
              this.$message.warning("请选择开始时间和结束时间，最多只能导出3个月数据");
              return false;
            }

            let days = moment(params.startTimeMax).diff(moment(params.startTimeMin),"months");

            if(days>=3){
              this.$message.warning("请选择开始时间和结束时间，最多只能导出3个月数据");
              return false;
            }
          }

          this.fullscreenLoading = true;
          exportRegLiveCrowdTypeApi(params).then((res) => {
            this.fullscreenLoading = false;
            importFile(res.data, this.crowdTypeName);
          });
      } catch (error) { }
    },

    // 导出总表
    async newadd() {
      try {
        let params = {
          hospitalId: this.userdoctor.hospitalId,
          orgCode: this.userdoctor.orgCode
        }
        this.fullscreenLoading = true;
        let res = await exportTotal(params);
        this.fullscreenLoading = false;
        importFile(res.data, "已入住人员列表全部数据")
      } catch (error) { }
    },

    // 导出列表数据
    exportListData() {
      let params = this.setParamsInfo();
      if(params.status != "1"){
        //如状态选择的全部或已解除时，时间范围必须选择，并且最多3个月的数据。如未选择提示“请选择开始时间和结束时间，最多只能导出3个月数据”
        if (!params.startTimeMin) {
          this.$message.warning("请选择开始时间和结束时间，最多只能导出3个月数据");
          return false;
        }

        let days = moment(params.startTimeMax).diff(moment(params.startTimeMin),"months");

        if(days>=3){
          this.$message.warning("请选择开始时间和结束时间，最多只能导出3个月数据");
          return false;
        }
      }
      messageBox(() => {
        this.fullscreenLoading = true;
        exportRegLiveListApi(params).then(res => {
          this.fullscreenLoading = false;
          importFile(res.data, '已入住人员列表数据')
        })
      }, "warning", '确定导出表格中的数据吗？')
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getListInfo();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getListInfo();
    },

    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let { searchValue, country, crowdTypeId, status, cardNo, roomNo, isolationPointId } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (crowdTypeId) params.crowdTypeId = crowdTypeId;
      if (status) params.status = status;
      if (cardNo) params.cardNo = cardNo;
      if (roomNo) params.roomNo = roomNo;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      if (this.timetime && this.timetime.length == 2) {
        params.startTimeMin = this.timetime[0] + " 00:00:00";
        params.startTimeMax = this.timetime[1] + " 23:59:59";
      }else {
        params.startTimeMin = "";
        params.startTimeMax = "";
      }

      return params;
    },

    // 查询
    queryDataInfo(type) {
      this.getListInfo(type);
    },

    // 获取列表数据
    async getListInfo(type ) {
      this.loading = true;
      let params = this.setParamsInfo();
      if(type=='btn'){
          params.pageNumber = 1;
      }
      // console.log('params',params);
      let result = await residentlistt({ params });
      let { code, data, msg } = result.data;
      console.log(result.data);
      if (code == 200) {
        let { totalRow, list } = data;
        this.tableData = list; //整个列表的数据
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    // 解除隔离
    async relive() {
      let params = {
        ...this.ruleForm,
        regId: this.ruleForm.id
      };
      try {
        let { data } = await relieveIsolate(params);
        if (data.code == 200) {
          // _this.tableData = data.data;
          this.$message({
            message: "解除成功！",
            type: "success"
          }).then(()=>{
            // console.log('cdshjabcvfdslgfabcvds')
            this.queryDataInfo()
          });
          
        }
      } catch (error) { }

      this.isjcgl = false

    },
    async printNotification() {
      let params = {
        regId: this.regId
      };
      try {
        let { data } = await downloadRelieveSignBook({ params });
        let url = window.URL.createObjectURL(data);
        window.open(url);
      } catch (error) { }
    },
    async sendNotification() {
      let params = {
        regId: this.regId
      };
      try {
        let { data } = await sendRelieveSignBook({ params });
        this.$notify({
          title: "解除隔离告知书发送",
          message: `${data.msg}`,
          dangerouslyUseHTMLString: true
        });
        this.isArrange = false;
      } catch (error) { }
    },
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/condition-filtrate.scss";

.main {
  width: 100%;
  height: 100%;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.content {
  height: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.minigldata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fhdsa {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>

<style scoped>
.el-link {
  margin-right: 10px;
}

.el-table>>>td {
  padding: 4px 0 !important;
}
</style>